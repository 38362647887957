/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */
@font-face {
    font-family: 'SF Pro Text';
    src: url('/assets/fonts/SF/SFProText-Regular.woff2') format('woff2'),
        url('/assets/fonts/SF/SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
/*@import '~quill/dist/quill.snow.css';*/

/* Prismjs */
@import "~prismjs/themes/prism-dark.css";
